<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
import Gen from '@helper/Gen';
import draggable from 'vuedraggable'

export default {
	name: "BoContact",
	extends: Base,
	components: {
       draggable,
    },
	data(){
		return {
			Name:"BoContact",
			row1:{},
			font_style:{},
			font_size:{},
            sub_font_size:{},
			data2:[],
			titleForm:'Add',
			detail:{name:'',phone:'',Email:'',subject:'',message:''}
		}
	},
	mounted(){
		this.$set(this.$root, 'page', this)
		this.refreshData()
	},
	watch:{
		'$route.query'(){
			this.refreshData()
		},
	},
	methods:{		
	    submitHero(){
	        BOGen.apirest('/'+this.Name, {data:this.row1,type:'updateHero'}, (err,resp)=>{
	            if(resp.error) return Gen.info(resp.message, resp.style,3000,'.hero-info')           
	            if(resp.success) {
	                Gen.info(resp.message, resp.style,2000,'.hero-info') 
	                this.refreshData()
	                setTimeout(()=>{
	                	$('[href="#collapseExample"]').click()
	                },2100)
	            }          
	        }, "POST");
	    },
        endDrag(){
            BOGen.apirest('/'+this.Name, {data:this.data2,type:'sort'}, (err,resp)=>{
                console.log(resp)
            }, "POST");
        },
        addAddress(){
        	this.$route.params.id = 'add'
        	this.row.type = 'add'
        	this.row.aca_is_active = 'Y'
        	$('#add_address').modal();
        },
        editAddress(v){
        	this.titleForm='Edit'
        	this.row.id=v
        	this.$route.params.id = v
        	this.row.type = 'get_update'
        	BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
        		this.row = resp.data
        		this.row.type = 'update'
        	},'POST')        	
        	$('#add_address').modal();
        },
        submitFormData(e, callback){
	      if(e&&e.btnLoading()) return;
	      BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
	        if(e) e.btnUnloading()
	        if(resp.error){
	          	if(this.errorFormCallback) return this.errorFormCallback(resp);
	          	if(callback) return callback(resp);
	         	Gen.info(resp.message, "danger")
	        }
	        if(resp.success){
	        	this.data2 = resp.data2
	          	Gen.info(resp.message, "success", 1000)
	          	setTimeout(()=>{
		         	$('#add_address').modal('hide');
		         	$('input[name="aca_name"]').val('')
		         	$('input[name="aca_address"]').val('')
		        },1000)
	        }
	      },"POST")
	    },
	    detailContact(v){
			$('#detail_contact').modal()
			this.detail.id= v.af_id
            this.detail.name= v.af_name
			this.detail.phone= v.af_phone
			this.detail.email= v.af_email_address
			this.detail.subject= v.af_attachment
			this.detail.message= v.af_address
            BOGen.apirest('/'+this.Name, {v,type:'updateContact'}, (err,resp)=>{
            	this.refreshData()
            }, "POST");   
        }
	}
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
	<div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
        	<VForm @resp="submitHero" method="post">
            <div class="card-body">
                <h5 class="card-title">Hero Image</h5>
                <div class="row">
                    <div class="col-md-6">
                        <div class="wrap_slider_img">
                            <img :src="uploader(row1.ash_image_desktop)" class="img-responsive" />
                            <div class="slider_name">
                                <p>Hero Image</p>
                            </div>
                            <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                        </div>
                    </div>
                </div>
                <div class="row collapse mt-4" id="collapseExample">
                    <div class="hero-info col-12"></div>
                    <div class="col-md-4 mb-3">
                        <BoField name="ash_image_desktop" mandatory>
                            <Uploader :param="{thumbnail:true}" name="ash_image_desktop" v-model="row1.ash_image_desktop" type="hero_descktop" uploadType="cropping"></Uploader>
                        </BoField>
                        <BoField name="ash_image_mobile" mandatory>
                            <Uploader :param="{thumbnail:true}" name="ash_image_mobile" v-model="row1.ash_image_mobile" type="hero_mobile" uploadType="cropping"></Uploader>
                        </BoField>
                    </div>
                    <div class="col-md-8 mb-3">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="panel_group">
                                    <div class="panel_heading">Heading</div>
                                    <div class="panel_body">
                                        <div class="row">
                                            <div class="col-md-9">
                                              <BoField name="ash_title_en" v-model="row1.ash_title_en"></BoField>
                                            </div>
                                            <div class="col-md-9">
                                                 <BoField name="ash_title_id" v-model="row1.ash_title_id"></BoField>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="control-label">Font Style</label>
                                                    <select class="form-control" v-model="row1.ash_title_font_type">
                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label class="control-label">Font Size </label>
                                                    <select class="form-control" v-model="row1.ash_title_size">
                                                        <option v-for="(v,k) in font_size" :key="k" :value="v">{{v}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <BoField :attr="{type:'color'}" name="ash_title_color" v-model="row1.ash_title_color"></BoField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="panel_group">
                                            <div class="panel_heading">Sub-Heading</div>
                                            <div class="panel_body">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <div class="form-group">
                                                            <BoField name="ash_sub_title_en" v-model="row1.ash_sub_title_en"></BoField>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="ash_sub_title_id" v-model="row1.ash_sub_title_id"></BoField>
                                                    </div>
                                                    <div class="col-md-4">
		                                                <div class="form-group">
		                                                    <label class="control-label">Font Style</label>
		                                                    <select class="form-control" v-model="row1.ash_sub_title_font_type">
		                                                        <option v-for="(v,k) in font_style" :key="k" :value="k">{{v}}</option>
		                                                    </select>
		                                                </div>
		                                            </div>
		                                            <div class="col-md-2">
		                                                <div class="form-group">
		                                                    <label class="control-label">Font Size </label>
		                                                    <select class="form-control" v-model="row1.ash_sub_title_size">
		                                                        <option v-for="(v,k) in sub_font_size" :key="k" :value="v">{{v}}</option>
		                                                    </select>
		                                                </div>
		                                            </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group">
                                                            <label class="control-label">Color </label>
                                                            <input class="form-control" type="color" id="favcolor" name="favcolor" v-model="row1.ash_sub_title_color">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 mt-3">
                                <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        	</VForm>
        </div>
      </div>

      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
         <div class="card">
            <div class="card-body">
               <div class="row">
                  <div class="col-md-10">
                     <h5 class="card-title">Contact Address</h5>
                  </div>
                  <div class="col-md-2"> 
                     <button @click="addAddress()" type="button" class="btn btn-info btn-rounded btn-block"><i class="fa fa-plus-circle mr-2"></i> Add New</button>
                  </div>
               </div>
                <draggable class="row mt-4" v-model="data2" tag="tbody" @end="endDrag">
                    <div class="col-md-4" v-for="(v2,k2) in data2" :key="k2" style="cursor: move;">
                    	<div style="border: 1px solid #e2e2e2;padding: 20px;">
                    		<div class="product-text">
                    			<h3>{{v2.aca_name}}</h3>
                    			<p>{{v2.aca_address}}</p>
                    		</div>
							<div class="row align-items-baseline">
								<div class="col-6">
									<a href="javascript:;" @click="editAddress(v2.aca_id)" class="text-inverse icon_action act_icon"
										v-tooltip="'Edit'"><i class="ti-marker-alt"></i></a>
									<a href="javascript:;" @click="deleteItemId(v2.aca_id,'row'+k2)" class="text-inverse icon_action act_icon" title="" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash"></i></a>
								</div>
								<div class="col-6 text-right">
                                    <span class="label label-success" v-if="v2.aca_is_active == 'Y'">Active</span>
                                    <span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>
                    	</div>
                    </div>
                 </draggable>
              <div class="col-12 text-center" v-if="!data2.length">
              	<h3 class="tc">Contact Address not found</h3>
              </div>
            </div>
         </div>
      </div>
      
      <div class="col-lg-12">
         <div class="card">
            <div class="card-body">
               <form action="#">
                  <div class="form-body">
	                  <VForm @resp="search">
                            <div class="row">
                                <div class="col-sm-5">
                                    <h5 class="card-title">{{ObjectName}} List</h5>
                                </div>
                                <div class="col-sm-3">
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group mb-0">
                                        <div class="input-group">
                                           <input type="text" v-model="filter.search"  class="form-control" placeholder="Search...">
                                           <div class="input-group-append">
                                              <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                           </div>
                                        </div>
                                     </div>
                                </div>
                                <div class="col-sm-1">
                                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                                </div>
                            </div>
                        </VForm>
	                  <div class="table-responsive ajax-table mt-3">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>Full name</th>
									<th>No Handphone</th>
									<th>Email Address</th>
									<th>Subject</th>
									<th>Submit Date</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.af_name||'-')}}</td>
									<td>{{(v.af_phone||'-')}}</td>
									<td>{{(v.af_email_address||'-')}}</td>
									<td>{{(v.af_attachment||'-')}}</td>
									<td>{{(v.af_submit_at).dates('format2')}}</td>
									<td class="btn-action">
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
										<a href="javascript:;" @click="detailContact(v)" class="text-inverse icon_action act_icon" v-tooltip="'Detail'"><i class="ti-eye"></i></a>
										<a :class="v.af_status=='U'? 'icon_action' : 'icon_action bg-success'" v-tooltip="v.af_status=='U'?'Unread':'Read'"><i class="ti-check"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
                  
                  </div>
               </form>
            </div>
         </div>
      </div>
    </div>
    <!-- Modal -->
    <div id="add_address" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
	   <div class="modal-dialog">
	   	<VForm @resp="submitFormData" method="post">
		      <div class="modal-content">
		         <div class="modal-header">
		            <h4 class="modal-title" id="prod_cat">{{titleForm+" "+ObjectName}}</h4>
		            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
		         </div>
		         <div class="modal-body">
		            <div class="row">
		             	<div class="col-12 info"></div>
			            <div class="col-md-12">
			                <BoField mandatory name="aca_name" :attr="{title:'Input Office Name',required:'required',maxlength:200,minlength:3}" v-model="row.aca_name"></BoField>
			            </div>
		                <div class="col-md-12">
		                	<BoField mandatory name="aca_address" :attr="{title:'Input Office Address',required:'required',maxlength:500,minlength:3}">
								<textarea rows="4"  v-bind="validation('af_answer_en')" name="aca_address" v-model="row.aca_address" class="form-control"></textarea>
							</BoField>
		               </div>
		               <div class="col-sm-12">
			                <BoField name="aca_is_active">
								<div class="row">
									<div class="col-2"><radio name="aca_is_active" :id="'select1'" v-model="row.aca_is_active" option="Y" :attr="validation('aca_is_active')">Active</radio></div>
									<div class="col-2"><radio name="aca_is_active" :id="'select2'" v-model="row.aca_is_active" option="N">Inactive</radio></div>
								</div>
							</BoField>
		               </div>
		            </div>
		         </div>
		         <div class="modal-footer">
		            <button type="submit" class="btn btn-rounded  btn-info btn-loading">Submit</button>
		         </div>
		      </div>
	  	</VForm>
	   </div>
	</div>

	<div id="detail_contact" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
	   <div class="modal-dialog">
	      <div class="modal-content">
	         <div class="modal-header">
	            <h4 class="modal-title" id="prod_cat">Detail Contact Form</h4>
	            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
	         </div>
	         <div class="modal-body">
	            <div class="row">
	               
	               <div class="col-md-12 mt-2">
	                  <div class="form-group">
	                     <label class="control-label">Full Name  </label>
	                   <p>{{(detail.name||'-')}}</p>
	                  </div>
	               </div>
	                <div class="col-md-12 mt-2">
	                  <div class="form-group">
	                     <label class="control-label">No Handphone </label>
	                     <p>{{(detail.phone||'-')}}</p>
	                  </div>
	               </div>
	                <div class="col-md-12 mt-2">
	                  <div class="form-group">
	                     <label class="control-label">Subject </label>
	                     <p>{{(detail.subject||'-')}}</p>
	                  </div>
	               </div>
	                <div class="col-md-12 mt-2">
	                  <div class="form-group">
	                     <label class="control-label">Message </label>
	                     <p>{{(detail.message||'-')}}</p>
	                  </div>
	               </div>	               
	            </div>
	         </div>
             <div class="modal-footer">
                <a class="btn btn-info" :href="'/contact/'+detail.id" target="_blank"><i class="fa fa-download"></i> Download</a>
             </div>
	      </div>
	   </div>
	</div>
	</div>
</template>
